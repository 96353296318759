.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #121212; /* Dark background */
  color: #e0e0e0; /* Lighter text for readability */
}

/* Checkbox styling */
.toggle-checkbox {
  position: relative;
  width: 40px; /* Width of the checkbox */
  height: 20px; /* Height of the checkbox */
  background-color: #333; /* Dark background similar to input fields */
  border-radius: 10px; /* Rounded corners */
  cursor: pointer;
  margin-left: 10px;
  margin-right: 10px;
}

.toggle-checkbox.checked::after{
  background-color: #4caf50; /* Light color for the knob */
}

/* Knob inside the checkbox */
.toggle-checkbox::after {
  content: '';
  position: absolute;
  width: 18px; /* Width of the knob */
  height: 18px; /* Height of the knob */
  background-color: #1e1e1e; /* Light color for the knob */
  border-radius: 50%;
  top: 1px; /* Slightly inside the main checkbox to create border effect */
  left: 1px; /* Start from the left by default */
  transition: all 0.3s; /* Smooth transition for the knob movement */
}

/* Styling when the checkbox is checked */
.toggle-checkbox.checked::after {
  left: 21px; /* Move the knob to the right */
}

/* Adding a label for the checkbox */
.toggle-label {
  color: #e0e0e0; /* Light color for readability */
  font-size: 0.9em;
  margin-left: 5px;
}

/* Flexbox container for aligning checkbox and label */
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  padding-right: 10px;
}

.ant-empty-description {
  color:#e0e0e0 !important;
}

.ant-modal-close-x {
  color:#e0e0e0 !important;
}

.ant-modal-title {
  color:#e0e0e0 !important;
}

.ant-modal-content {
  background-color: #121212 !important; 
}

.ant-modal-title {
  background-color: #121212 !important; 
}

.popi-modal {
  color:#e0e0e0 !important;
}

.chat-name {
  color:#6a829e !important;
  font-size: x-large;
  font-weight: bold;
}

.chat-message {
  color:#acacac !important;
  font-size: medium;
}

.ant-list-empty-text {
  color:#e0e0e0 !important;
}

.chat-container {
  width: 85%;
  overflow-y: auto;
  border: 1px solid #333;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #1e1e1e;
  height: 100%;
}

.message-list {
  max-height: 100%;
  overflow-y: auto;
}

.message-list .ant-list-item {
  color: #e0e0e0; /* Ensure list text is light for visibility */
}

.app-logo {
  height: 60px; /* Adjust size as needed */
}

.app-slogan {
  font-style: italic;
  color: #6a829e; /* Choose a color that fits your app's theme */
  font-size: 1.0em;
  margin-top: 5px;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.message-item {
  background-color: #262626; /* Each message item with a distinct background */
  padding: 10px !important;
  margin-bottom: 5px;
  border-radius: 5px; /* Slightly rounded edges for a softer look */
  color: #e0e0e0; /* Light text for message items */
}

.input-form {
  width: 85%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.input-field {
  flex: 1;
  margin-right: 10px;
  background-color: #333; /* Dark input field */
  color: #fff; /* White text */
  border: none;
  padding: 10px;
  border-radius: 5px;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbb !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #bbb;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #bbb;
}

.submit-button {
  background-color: #4caf50;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.submit-button:hover {
  background-color: #43a047; /* Slightly darker on hover */
}

.submit-container{
  display: grid;
  max-width: 36%;
}

.error-message {
  color: #ff5722; /* Error message in a noticeable color */
}
