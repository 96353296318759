/* Ant Design Overrides */
.ant-empty-description,
.ant-modal-close-x,
.ant-modal-title,
.ant-modal-content,
.ant-modal-title,
.popi-modal,
.ant-list-empty-text {
  color: #e0e0e0 !important;
  background-color: #121212 !important; 
}

.reset-page {
  position: relative;
  top: -100px;
}

/* Chat Styles */
.chat-name {
  color: #6a829e !important;
  font-size: x-large;
  font-weight: bold;
}

.chat-message {
  color: #acacac !important;
  font-size: medium;
}

.chat-container {
  width: 85%;
  overflow-y: auto;
  border: 1px solid #333;
  margin-bottom: 20px;
  padding: 10px;
  background-color: #1e1e1e;
  height: 100%;
}

.message-list {
  max-height: 100%;
  overflow-y: auto;
}

.message-list .ant-list-item {
  color: #e0e0e0;
}

/* Header and Logo Styles */
.app-logo {
  height: 60px;
}

.app-slogan {
  font-style: italic;
  color: #6a829e;
  font-size: 1.0em;
  margin-top: 5px;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

/* Message Item Styles */
.message-item {
  background-color: #262626;
  padding: 10px !important;
  margin-bottom: 5px;
  border-radius: 5px;
  color: #e0e0e0;
}

/* Input Form Styles */
.input-form {
  width: 85%;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

.input-field {
  flex: 1;
  margin-right: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
}

/* Placeholder Styles */
::placeholder,
:-ms-input-placeholder,
::-ms-input-placeholder {
  color: #bbb !important;
}

/* Error Message Styles */
.error-message {
  color: #ff5722;
}
